// eslint-disable-next-line import/no-unresolved
import { set } from '../utils';

const Mutations = class {
  constructor(endPoint) {
    this[`SET_${endPoint}`] = set(endPoint);
  }
};

export default Mutations;
